import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState,useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { initialState } from 'store/initialStateSlice';
import { ROUTE_PATHS } from 'routes';
import { handleGetRequests } from 'data/api/handleRequests';
import { setDefaultCredentials } from '@deck.gl/carto';
import { setCredentials } from '@carto/react-redux';
import { setUser } from 'store/userSlice';

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { accessToken } = useSelector((state) => state.carto.credentials);
  const {oohAccess, inMarketAccess} = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {credentials} = useSelector(state => state.carto);
  const [fetchingToken, setFetchingToken] = useState(true);

  const currentPath = useLocation().pathname;

  useEffect(() => {
    const fetchAccessToken = async () => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        try {
          const { data, status } = await handleGetRequests('access', storedUser.id);
          if (status === 200 && data.access_token) {
            setDefaultCredentials({ ...credentials, accessToken: data.access_token });
            dispatch(setCredentials({ ...credentials, accessToken: data.access_token }));
            const stateUpdate = {
              id: storedUser.id,
              admin: data.is_admin,
              oohAccess: !!data.has_ooh,
              inMarketAccess: !!data.has_inmarket
            };
            dispatch(setUser(stateUpdate));
          }else{
            localStorage.removeItem('user');
            window.location.reload();
          }
        } catch (err) {
          console.error(err);
        }
      }
      setFetchingToken(false);
    };

    if (!accessToken) {
      fetchAccessToken();
    } else {
      setFetchingToken(false);
    }
  }, [isLoading, isAuthenticated, accessToken, dispatch, credentials]);

  if (!initialState.oauth) {
    return children;
  }

  const notAuthenticated = !isLoading && !isAuthenticated && !accessToken && !fetchingToken;

  if (notAuthenticated) {
    return <Navigate to={ROUTE_PATHS.LOGIN} />;
  }

  if(!oohAccess && currentPath === '/'){
    return <Navigate to={ROUTE_PATHS.CAMPAIGN_VISUALISER} />;
  }

  if(!inMarketAccess && currentPath === '/in-market-audience'){
    return <Navigate to={ROUTE_PATHS.CAMPAIGN_VISUALISER} />;
  }

  return !!accessToken && children;
}
