import { useEffect, useState } from 'react';
import { Divider, Hidden, Grid } from '@mui/material';
import { AppBar } from '@carto/react-ui';
import DrawerMenu from './DrawerMenu';
import NavigationMenu from './NavigationMenu';
import Logo from './Logo';
import UserMenu from './UserMenu';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { toggleSidebar } from '../../../store/appSlice';
import cornerIcon from 'assets/img/corner_icon.png';
import { setCredentials } from '@carto/react-redux';
import { setDefaultCredentials } from '@deck.gl/carto';
import { initialState } from 'store/initialStateSlice';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogoutIcon from '@mui/icons-material/Logout';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const {credentials} = useSelector(state => state.carto)

  const CustomIconButton = ({ onClick }) => (
    <IconButton color="inherit" aria-label="toggle sidebar" onClick={onClick}>
       <img src={cornerIcon} alt="Menu"  style={{
        position: 'absolute',
        width: '21px',
        height: '30px',
        marginRight: '5px'
      }} />
    </IconButton>
   );

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <StyledAppBar
      component='header'
      position='relative'
      showBurgerMenu
      onClickMenu={handleDrawerToggle}
      // brandLogo={<Logo />}
      // brandText='Vicinity Visualiser'
    >
      <Hidden mdUp>
        <DrawerMenu drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      </Hidden>

      <Hidden mdDown>
      <CustomIconButton onClick={() => dispatch(toggleSidebar())} />

        <StyledDivider orientation='vertical' flexItem light></StyledDivider>
        <NavigationMenu />
        <Grid container item xs justifyContent='flex-end'>
          {credentials.accessToken && credentials.accessToken.length > 0 ? (
            <span 
              style={{display: 'flex', justifyContent: 'center', cursor: 'pointer', color: '#adb2b8', transition: 'color 0.3s ease'}}
              onClick={() => {
                localStorage.removeItem('user');
                setDefaultCredentials(initialState.credentials)
                dispatch(setCredentials(initialState.credentials));
                window.location.reload();
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = '#ffffff')}
              onMouseLeave={(e) => (e.currentTarget.style.color = '#adb2b8')}
            >
              <h6 style={{margin: '0 5px', padding: '0'}}>Exit</h6>
              <LogoutIcon sx={{cursor: 'pointer'}}/>
            </span>
          ): null}
          <UserMenu />
        </Grid>
      </Hidden>
    </StyledAppBar>
  );
}
