const populationGroupData = [
    {
      value: 'black_african',
      label: 'Black'
    },
    {
      value: 'coloured',
      label: 'Coloured'
    },
    {
      value: 'white',
      label: 'White'
    },
    {
      value: 'indian_or_asian',
      label: 'Indian/Asian'
    },
    {
      value: 'other',
      label: 'Other'
    }
  ]

  const ageGroupData = [
    {
      value: 'group_15_19',
      label: '15 - 19'
    },
    {
      value: 'group_20_24',
      label: '20 - 24'
    },
    {
      value: 'group_25_34',
      label: '25 - 34'
    },
    {
      value: 'group_35_44',
      label: '35 - 44'
    },
    {
      value: 'group_45_49',
      label: '45 - 49'
    },
    {
      value: 'group_50_54',
      label: '50 - 54'
    },
    {
      value: 'group_55_64',
      label: '55 - 64'
    },
    {
      value: 'group_65_70',
      label: '65 - 70'
    },
    {
      value: 'group_71_75',
      label: '71 - 75'
    },
    {
      value: 'group_above_75',
      label: 'Over 75'
    },
    {
      value: 'group_below_15',
      label: 'Under 15'
    },
  ]

  const genderData = [
    {
      value: 'male',
      label: 'Male'
    },
    {
      value: 'female',
      label: 'Female'
    }
  ]

  const languageData = [
    {
      value: 'afrikaans',
      label: 'Afrikaans'
    },
    {
      value: 'english',
      label: 'English'
    },
    {
      value: 'isindebele',
      label: 'Isindebele'
    },
    {
      value: 'isixhosa',
      label: 'IsiXhosa'
    },
    {
      value: 'isizulu',
      label: 'IsiZulu'
    },
    {
      value: 'sepedi',
      label: 'Sepedi'
    },
    {
      value: 'sesotho',
      label: 'Sesotho'
    },
    {
      value: 'setswana',
      label: 'Setswana'
    },
    {
      value: 'siswati',
      label: 'Siswati'
    },
    {
      value: 'tshivenda',
      label: 'Tshivenda'
    },
    {
      value: 'xitsonga',
      label: 'Xitsonga'
    },
    {
      value: 'sign_language',
      label: 'Sign Language'
    },
    {
      value: 'other',
      label: 'Other'
    },
  ]


  export {populationGroupData,languageData,genderData,ageGroupData}