import { createSlice } from '@reduxjs/toolkit';
import { DemographicsValues } from 'data/constants/demographics';
import { populationGroupData, ageGroupData, genderData, languageData} from 'data/constants/demographicsData';

const layerListenerSlice = createSlice({
  name: 'layerListener',
  initialState: {
    unexposedTimeSeriesDate: "",
    exposedTimeSeriesDate: "",
    poiSelections: [],
    webAnalyticsP1Selections: [],
    webAnalyticsPlacementSelections: [],
    visibleBrands: [],
    demographicsViewOptionsDefault: [
      {
        value: DemographicsValues.Province,
        label: 'Province'
      },
      {
        value: DemographicsValues.MainPlace,
        label: 'City'
      },
      {
        value: DemographicsValues.SubPlace,
        label: 'Suburb'
      },
      {
        value: DemographicsValues.SmallArea,
        label: 'Small-Areas'
      }
    ],
    demographicsViewOptions: [
      {
        value: DemographicsValues.Province,
        label: 'Province'
      },
      {
        value: DemographicsValues.MainPlace,
        label: 'City'
      },
      {
        value: DemographicsValues.SubPlace,
        label: 'Suburb'
      },
      {
        value: DemographicsValues.SmallArea,
        label: 'Small-Areas'
      }
    ],
    demographicsView: [{
      value: DemographicsValues.Province,
      label: 'Province'
    }],
    populationGroupSelection: [...populationGroupData],
    ageGroupSelection: [...ageGroupData],
    genderSelection: [...genderData],
    languageSelection: [...languageData],
    refreshLayersTrigger: false,
    rightClickTrigger: false,
    resetDemographicsTrigger: false,
    visitsAnalysisFetched: false,
    showVisitsAnalysisButton: false,
    toggleTimeSeriesVisibility: false
  },
  reducers: {
    setUnexposedTimeSeriesDate: (state, action) => {
      state.unexposedTimeSeriesDate = action.payload;
    },
    setExposedTimeSeriesDate: (state, action) => {
      state.exposedTimeSeriesDate = action.payload
    },
    setPOISelections: (state,action) => {
      state.poiSelections = action.payload;
    },
    setWebAnalyticsP1Selections: (state,action) => {
      const currentSet = new Set(state.webAnalyticsP1Selections);
      action.payload.forEach(item => currentSet.add(item));
      state.webAnalyticsP1Selections = Array.from(currentSet);
    },
    setWebAnalyticsPlacementSelections: (state,action) => {
      const currentSet = new Set(state.webAnalyticsPlacementSelections);
      action.payload.forEach(item => currentSet.add(item));
      state.webAnalyticsPlacementSelections = Array.from(currentSet);
    },
    setVisibleBrands: (state, action) => {
      state.visibleBrands = action.payload;
    },
    setPopulationGroupSelection: (state,action) => {
      state.populationGroupSelection = action.payload;
    },
    setAgeGroupSelection: (state,action) => {
      state.ageGroupSelection = action.payload;
    },
    setGenderSelection: (state,action) => {
      state.genderSelection = action.payload;
    },
    setLanguageSelection: (state,action) => {
      state.languageSelection = action.payload;
    },
    setDemographicsView: (state, action) => {
      state.demographicsView = action.payload;
    },
    setDemographicsViewOptions: (state, action) => {
      state.demographicsViewOptions = action.payload
    },
    resetDemographicsViewOptions: (state) => {
      state.demographicsViewOptions = [
        {
          value: DemographicsValues.Province,
          label: 'Province'
        },
        {
          value: DemographicsValues.MainPlace,
          label: 'City'
        },
        {
          value: DemographicsValues.SubPlace,
          label: 'Suburb'
        },
        {
          value: DemographicsValues.SmallArea,
          label: 'Small-Areas'
        }
      ];
    },
    setRefreshLayersTrigger: (state, action) => {
      state.refreshLayersTrigger = action.payload;
    },
    setRightClickTrigger: (state) => {
      state.rightClickTrigger = !state.rightClickTrigger;
    },
    setResetDemographicsTrigger: (state) => {
      state.resetDemographicsTrigger  = !state.resetDemographicsTrigger;
    },
    setVisitsAnalysisFetched: (state, action) => {
      state.visitsAnalysisFetched =  action.payload;
    },
    setShowVisitsAnalysisButton: (state, action) => {
      state.showVisitsAnalysisButton = action.payload;
    },
    setToggleTimeSeriesVisibility: (state, action) => {
      state.toggleTimeSeriesVisibility = action.payload;
    }
  }
});

export default layerListenerSlice.reducer;
export const { 
  setUnexposedTimeSeriesDate,
  setExposedTimeSeriesDate,
  setPOISelections,
  setWebAnalyticsP1Selections,
  setWebAnalyticsPlacementSelections,
  setVisibleBrands,
  setLanguageSelection,
  setGenderSelection,
  setAgeGroupSelection,
  setPopulationGroupSelection,
  setDemographicsView,
  setDemographicsViewOptions,
  resetDemographicsViewOptions,
  setRefreshLayersTrigger,
  setRightClickTrigger,
  setResetDemographicsTrigger,
  setVisitsAnalysisFetched,
  setShowVisitsAnalysisButton,
  setToggleTimeSeriesVisibility
} = layerListenerSlice.actions;
