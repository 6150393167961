import axios from 'axios';

export const handlePaginatedPostRequest = async (endPoint, body, onPageFetched) => {
    const url = process.env.REACT_APP_BASE_URL + '/' + endPoint;
    let allData = [];
    let page = 1;
    const limit = 1000000; 
  
    try {
      while (true) {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json',
            'x-platform': process.env.REACT_APP_SECRET_PLATFORM,
            'x-token': process.env.REACT_APP_SECRET_TOKEN
          },
          body: JSON.stringify({ ...body, page, limit })
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const responseData = await response.json();
        allData = allData.concat(responseData);

        if(onPageFetched){
          onPageFetched(responseData)
        }

        // Break if no more data is returned
        if (responseData.length < limit) {
          break;
        }
  
        page += 1; // Move to the next page
      }
  
      return { data: allData };
  
    } catch (err) {
      console.error("Error fetching data: ", err);
      throw err;
    }
  };

  export const handlePostRequest = async (endPoint, body) => {
    const url = process.env.REACT_APP_BASE_URL + '/' + endPoint;
    
    try{
        const response = await axios.post(url, body, {
            headers: {
                "Content-Type": 'application/json',
                'x-platform': process.env.REACT_APP_SECRET_PLATFORM,
                'x-token' : process.env.REACT_APP_SECRET_TOKEN
            }
        })
        if(typeof response.data === 'string'){
            const parsedData = JSON.parse(response.data);
            response.data = parsedData;
        }
        
        return response;
    }catch (err){
      if(err.response){
        const {status} = err.response;
        console.error("Error fetching data: ", err);
        if(status === 401){
          alert('Invalid username or  password');
        } else if (status === 500){
          alert('Internal Server Error. Please try again later');
        } else {
          alert(`An Error Occured ${status}`);
        }
      }
    }
}

export const handleGetRequests = async (endPoint, id = '') => {
    const url = `${process.env.REACT_APP_BASE_URL}/${endPoint}${id ? `/${id}` : ''}`;
    
    try{
        const response = await axios.get(url, {
            headers: {
                "Content-Type": 'application/json',
                'x-platform': process.env.REACT_APP_SECRET_PLATFORM,
                'x-token' : process.env.REACT_APP_SECRET_TOKEN
            }
        })
        
        if(typeof response.data === 'string'){
            const parsedData = JSON.parse(response.data);
            response.data = parsedData;
        }

        return response;
    }catch (err){
        console.error("Error fetching data: ", err);
        throw err;
    }
}