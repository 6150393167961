import { useRoutes } from 'react-router-dom';
import { CssBaseline, Grid, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import LazyLoadRoute from 'components/common/LazyLoadRoute';
import theme from './theme';
import routes from './routes';
import useAuth from './hooks/Auth0';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from 'store/userSlice';
import { setCredentials } from '@carto/react-redux';
import { setDefaultCredentials } from '@deck.gl/carto';
import { initialState } from 'store/initialStateSlice';

import CircularProgressWithIcon from 'components/custom/CircularProgressWithIcon';
import { useEffect } from 'react';
import { setLoadingApp } from 'store/appSlice'
import Maintanance from 'components/views/Maintanance';
import { IntlProvider } from 'react-intl';
import { handleGetRequests } from 'data/api/handleRequests';

let credentials = { ...initialState.credentials }

const GridApp = styled(Grid)(() => ({
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: '100%'
}));

export default function App() {
  const { loadingApp, loadingMap } = useSelector((state) => state.app);

  const maintananceMode = process.env.REACT_APP_MAINTENANCE_MODE;

  const routing = useRoutes(routes);
  useAuth();

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const language = 'en';
  const messages = {};

  return (
    <IntlProvider locale={language} messages={messages}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GridApp container>
            {maintananceMode === true ? (
              <Maintanance />
            )
          :
            (
              <>
              <LazyLoadRoute>{routing}</LazyLoadRoute>
              {loadingApp && (
                <div style={overlayStyle}>
                  <CircularProgressWithIcon className='loader' showLoadingText={true}/>
                </div>
              )}
            </>
            )}
            
          </GridApp>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
    
  );
}
