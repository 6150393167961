import { createSlice } from '@reduxjs/toolkit';

const CampaignRequestDataSlice = createSlice({
  name: 'campaignRequestData',
  initialState: {
    brandLocationsRequestData: {},
    POIRequestData: {},
    webAnalyticsRequestData: {},
    exposedVisitRequestData: {},
    unexposedVisitRequestData: {},
    visitsAnalysisRequestData: {},
    locationBankRequestData: {},
    unexposedUpliftRequestData: {},
    exposedUpliftRequestData: {},
    clicksRequestData: {},
    impressionsRequestData: {},
    demographicsRequestData: {},
    audienceReachRequestData: {}
  },
  reducers: {
    setBrandLocationRequestData: (state, action) => {
      state.brandLocationsRequestData = action.payload;
    },
    setPOIRequestData: (state, action) => {
      state.POIRequestData = action.payload;
    },
    setWebAnalyticsRequestData: (state, action) => {
      state.webAnalyticsRequestData = action.payload;
    },
    setExposedVisitRequestData: (state, action) => {
      state.exposedVisitRequestData = action.payload;
    },
    setUnexposedVisitRequestData: (state, action) => {
      state.unexposedVisitRequestData = action.payload;
    },
    setVisitsAnalysisRequestData: (state, action) => {
      state.visitsAnalysisRequestData = action.payload;
    },
    setLocationBankRequestData: (state, action) => {
      state.locationBankRequestData = action.payload;
    },
    setUnexposedUpliftRequestData: (state, action) => {
      state.unexposedUpliftRequestData = action.payload;
    },
    setExposedUpliftRequestData: (state, action) => {
      state.exposedUpliftRequestData = action.payload;
    },
    setClicksRequestData: (state, action) => {
      state.clicksRequestData = action.payload;
    },
    setImpressionsRequestData: (state, action) => {
      state.impressionsRequestData = action.payload;
    },
    setDemographicsRequestData: (state, action) => {
      state.demographicsRequestData = action.payload;
    },
    setAudienceReachRequestData: (state,action) => {
      state.audienceReachRequestData = action.payload;
    }
  }
});

export default CampaignRequestDataSlice.reducer;
export const { 
  setBrandLocationRequestData,
  setPOIRequestData,
  setWebAnalyticsRequestData,
  setExposedVisitRequestData,
  setUnexposedVisitRequestData,
  setVisitsAnalysisRequestData,
  setLocationBankRequestData,
  setUnexposedUpliftRequestData,
  setExposedUpliftRequestData,
  setClicksRequestData,
  setImpressionsRequestData,
  setDemographicsRequestData,
  setAudienceReachRequestData
 } = CampaignRequestDataSlice.actions;
