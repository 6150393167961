import { createSlice } from '@reduxjs/toolkit';

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    admin: false,
    inMarketAccess: false,
    oohAccess: false,
    id: ''
  },
  reducers: {
    setUser: (state, action) => {
        state.admin = action.payload.admin;
        state.inMarketAccess = action.payload.inMarketAccess;
        state.oohAccess = action.payload.oohAccess;
        state.id = action.payload.id;
    }
  }
});

export default UserSlice.reducer;
export const { 
  setUser
} = UserSlice.actions;
